import { Alert, AlertDescription } from "@/components/shared/ui/alert";
import { AlertCircle } from "lucide-react";

interface InventoryError {
    productId: number;
    message: string;
    availableUnits: number;
}

interface InventoryWarningsProps {
    inventoryErrors: InventoryError[];
    productNames: { [key: number]: string };
}

export function InventoryWarnings({ inventoryErrors }: InventoryWarningsProps) {
    return (
        <div className="mb-4">
            {inventoryErrors.map((error) => (
                <Alert
                    key={error.productId}
                    variant="default"
                    className="flex items-center justify-between border-none text-amber-600"
                >
                    <div className="flex items-center gap-2">
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription>{error.message}</AlertDescription>
                    </div>
                    {/* Does not work yet */}
                    {/* <div className="flex items-center gap-2">
                            <Tooltip delayDuration={200}>
                                <TooltipTrigger asChild>
                                    <HelpCircle className="h-4 w-4 cursor-help" />
                                </TooltipTrigger>
                                <TooltipContent className="max-w-xs">
                                    <p>
                                        Auto Reduce will automatically adjust your order quantity to
                                        match the available inventory, removing any backorder units.
                                        It will prioritize the lowest cost transactions.
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                            <Button
                                variant="outline"
                                size="sm"
                                onClick={() =>
                                    onAdjustInventory(error.productId, error.availableUnits)
                                }
                            >
                                Auto Reduce
                            </Button>
                        </div> */}
                </Alert>
            ))}
        </div>
    );
}
