import CartPriceDisplay from "@/components/features/cart-edit/cart-price-display";
import ImageWithFallback from "@/components/shared/ui/image-with-fallback";
import { TransactionGroup } from "@/lib/cart/cart-types";
import { Cannabis } from "lucide-react";
import Link from "next/link";
import NumUnitsDisplay from "./num-units-display";

interface ProductHeaderProps {
    group: TransactionGroup;
    closeCart?: () => void;
}

export function ProductHeader({ group, closeCart }: ProductHeaderProps) {
    return (
        <div className={`w-full`}>
            <div className="flex h-full w-full flex-row items-start gap-4">
                <Link
                    href={`/${group.state}/product/${group.productID}`}
                    onClick={() => {
                        if (closeCart) {
                            closeCart();
                        }
                    }}
                    className="flex h-full w-full flex-row items-start gap-4"
                >
                    <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-md bg-gray-100 sm:h-20 sm:w-20">
                        {group.thumbnailImageURL ? (
                            <ImageWithFallback
                                unoptimized
                                className="h-full w-full border object-contain"
                                primarySrc={group.thumbnailImageURL}
                                fallbackSrc={null}
                                alt={group.productName}
                                fill
                            />
                        ) : (
                            <div className="flex h-full w-full items-center justify-center">
                                <Cannabis className="h-8 w-8 text-gray-400" />
                            </div>
                        )}
                    </div>

                    <div className="flex w-full flex-1 flex-col justify-between gap-4 sm:flex-row">
                        <div className="min-w-0 flex-1">
                            <h3 className="break-words text-lg font-medium text-gray-900 md:text-lg">
                                {group.productName}
                            </h3>

                            <div className="mt-2 flex flex-row items-baseline justify-start gap-2 text-sm text-gray-600">
                                <NumUnitsDisplay
                                    numUnits={group.totalUnits}
                                    wholesaleUnitsPerCase={group.wholesaleUnitsPerCase}
                                />
                                {group.backorderUnits > 0 && (
                                    <div className="mt-1">
                                        <span className="text-amber-600">
                                            Backorder Units: {group.backorderUnits}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex w-full flex-row justify-end gap-2 sm:w-auto sm:flex-col sm:items-end md:justify-between">
                            <div className="text-right">
                                <CartPriceDisplay
                                    variant="medium"
                                    disableTooltip={true}
                                    displayPrice={group.totalExtPrice}
                                    fullPriceDisplay={group.totalFullPrice}
                                    unitDiscount={group.totalDiscount / group.totalUnits}
                                    unitPrice={0}
                                    unitFullPrice={0}
                                    numUnits={group.totalUnits}
                                    hasQuantityDiscount={false}
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
}
