"use client";

import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import Logout from "../navbar/logout";

export default function LoginLink({ loggedIn }: { loggedIn: boolean }) {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const currentUrl = searchParams.toString()
        ? `${pathname}?${searchParams.toString()}`
        : pathname;

    const encodedReturnUrl = encodeURIComponent(currentUrl);

    return !loggedIn ? (
        <Link
            href={`/login?returnURL=${encodedReturnUrl}`}
            className="pr-2 text-sm font-medium text-neutral-500 hover:text-black dark:text-neutral-400 dark:hover:text-neutral-300"
        >
            Login
        </Link>
    ) : (
        <Logout />
    );
}
