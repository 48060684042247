"use client";

import { TooltipProvider } from "@/components/shared/ui/tooltip";
import { CurrentCustomerProvider } from "@/state-management/context/current-customer-context";
import { SessionProvider } from "next-auth/react";

export default function Providers({ children }: { children: React.ReactNode }) {
    return (
        <SessionProvider>
            <CurrentCustomerProvider>
                <TooltipProvider>{children}</TooltipProvider>
            </CurrentCustomerProvider>
        </SessionProvider>
    );
}
