export default function NumUnitsDisplay({
    numUnits,
    wholesaleUnitsPerCase
}: {
    numUnits: number;
    wholesaleUnitsPerCase: number;
}) {
    const cases = Math.floor(Number(numUnits / wholesaleUnitsPerCase));
    const remainder = numUnits % wholesaleUnitsPerCase;
    return (
        <p className="text-sm text-muted-foreground">
            {numUnits} {`unit${numUnits > 1 ? "s" : ""}`}
            {cases >= 1
                ? ` (${cases} case${cases > 1 ? "s" : ""}${remainder > 0 ? ` + ${remainder} unit${remainder > 1 ? "s" : ""}` : ""})`
                : ""}
        </p>
    );
}
