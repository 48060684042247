"use client";

import { findOrCreateCartAndSetCookie } from "@/components/features/cart-modal/actions";
import { Button } from "@/components/shared/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/shared/ui/popover";
import { Skeleton } from "@/components/shared/ui/skeleton";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/shared/ui/tooltip";
import { ShopCustomer } from "@/lib/customers/customer-types";
import { useCurrentCustomer } from "@/state-management/context/current-customer-context";
import { ChevronDown, ShoppingCart, Store, User } from "lucide-react";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { useCallback, useEffect, useState } from "react";

interface CurrentCustomerProps {
    favorites: ShopCustomer[];
    currentCustomer?: ShopCustomer;
    singleMode?: boolean;
}

export default function CurrentCustomer({
    favorites = [],
    currentCustomer,
    singleMode = false
}: CurrentCustomerProps) {
    const [isMounted, setIsMounted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { update } = useSession();
    const { customer, setCurrentCustomer } = useCurrentCustomer();

    useEffect(() => {
        setIsMounted(true);
        if (currentCustomer) {
            setCurrentCustomer(currentCustomer);
        }
    }, [currentCustomer, setCurrentCustomer]);

    const handleCustomerClick = useCallback(
        async (favorite: ShopCustomer) => {
            try {
                const newSession = await update({
                    currentCustomerID: favorite.customerID,
                    defaultStateCode: favorite.state
                });

                if (newSession?.currentCustomerID === favorite.customerID) {
                    setCurrentCustomer(favorite);
                    await findOrCreateCartAndSetCookie();
                } else {
                    throw new Error("Session update failed");
                }
            } catch (error) {
                console.error("Error updating session:", error);
                alert("An error occurred while updating session");
            }
            setIsOpen(false);
        },
        [update, setCurrentCustomer]
    );

    if (!isMounted) {
        return (
            <div className="flex flex-col space-y-2">
                <Skeleton className="h-6 w-48" />
                <Skeleton className="h-4 w-24" />
            </div>
        );
    }

    const sortedFavorites = [...favorites].sort(
        (a, b) => (b.pendingCartQuantity ?? 0) - (a.pendingCartQuantity ?? 0)
    );

    const currentContent = customer ? (
        <div className="flex items-center gap-1.5 text-sm">
            <Store className="h-4 w-4 text-gray-500" aria-hidden="true" />
            <span className="font-medium">
                <span className="text-gray-500">({customer.customerID})</span>{" "}
                {customer.customerName}
                <span className="ml-1 text-gray-500">• {customer.state}</span>
            </span>
        </div>
    ) : (
        <div className="flex items-center gap-1.5 text-sm">
            <User className="h-4 w-4" />
            Select Customer
        </div>
    );

    return singleMode ? (
        <>{currentContent}</>
    ) : (
        <div className="flex flex-col">
            <Popover open={isOpen} onOpenChange={setIsOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="ghost"
                        className="flex w-fit flex-row gap-1.5 p-2 hover:bg-gray-100"
                    >
                        {currentContent}
                        <ChevronDown
                            className={`h-4 w-4 transition-transform ${isOpen ? "rotate-180" : ""}`}
                            aria-hidden="true"
                        />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-72 p-2">
                    <div className="flex flex-col space-y-1">
                        <div className="px-2 py-1.5 text-sm font-medium text-gray-500">
                            Favorite Customers
                        </div>
                        {sortedFavorites.length > 0 ? (
                            sortedFavorites.map((favorite) => (
                                <button
                                    key={favorite.customerID}
                                    onClick={async () => {
                                        const oldState = customer?.state;
                                        await handleCustomerClick(favorite);
                                        if (oldState !== favorite?.state) {
                                            // have to reload the page to change the state in the url
                                            window.location.reload();
                                        }
                                    }}
                                    className={`flex w-full items-center justify-between rounded-md px-2 py-1.5 text-left hover:bg-gray-100 ${customer?.customerID === favorite.customerID ? "bg-gray-100" : ""}`}
                                >
                                    <div className="flex items-center gap-2">
                                        <Store className="h-4 w-4 text-gray-500" />
                                        <span className="text-sm">
                                            <span className="text-gray-500">
                                                ({favorite.customerID})
                                            </span>{" "}
                                            {favorite.customerName}
                                            <span className="ml-1 text-gray-500">
                                                • {favorite.state}
                                            </span>
                                        </span>
                                    </div>
                                    {favorite.pendingCartQuantity !== undefined &&
                                        favorite.pendingCartQuantity > 0 && (
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div className="flex items-center gap-1 text-xs text-slate-600">
                                                        <ShoppingCart className="h-3 w-3" />
                                                        <span>{favorite.pendingCartQuantity}</span>
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    <p>
                                                        You have {favorite.pendingCartQuantity}{" "}
                                                        {favorite.pendingCartQuantity === 1
                                                            ? "case "
                                                            : "cases "}
                                                        in the cart for this customer
                                                    </p>
                                                </TooltipContent>
                                            </Tooltip>
                                        )}
                                </button>
                            ))
                        ) : (
                            <div className="px-2 py-1.5 text-sm text-gray-500">
                                No favorite customers yet. You can add some from the customer list.
                            </div>
                        )}
                        <div className="my-1 border-t" />
                        <Button asChild variant="ghost" className="w-full justify-start gap-2 ">
                            <Link href="/select_customer">
                                <Store className="h-4 w-4" />
                                Browse All Customers
                            </Link>
                        </Button>
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
}
